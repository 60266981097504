.custom-checkbox {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 8px;
    background-color: #ccc;
    transition: background-color 0.3s ease;
    position: relative;
    white-space: nowrap;

}

.custom-checkbox.checked {
    background-color: white;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-checkbox-box {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.custom-checkbox .checkmark,
.custom-checkbox .cross {
    font-size: 16px;
    color: white;
    line-height: 16px;
    text-align: center;
}

.custom-checkbox .checkmark {
    display: none;
}

.custom-checkbox .cross {
    display: block;
}

.custom-checkbox input:checked~.custom-checkbox-box .checkmark {
    display: block;
}

.custom-checkbox input:checked~.custom-checkbox-box .cross {
    display: none;
}

.label-text {
    font-size: 16px;
    font-weight: bold;
    color: black;
    margin-right: 5px;
}

.dyn-inputs {
    display: flex;
    align-items: center;
}