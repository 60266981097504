.card-settings-container {
    white-space: nowrap;
    display: flex;
    gap: 100px;
}

.sett-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 800px;
    margin-top: 50px;
    gap: 20px;
    color: white;
}

.naming,
.symbols,
.description-case,
.img-case,
.send-case {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px
}

.naming {
    margin-left: -40px
}

.symbols {
    margin-left: 25px;
}

.naming input,
.symbol-input {
    width: 250px;

    height: 40px;
    border-radius: 8px;
    padding: 0 10px;
}

.symbols input[type="checkbox"] {
    margin-left: 10px;
}

.description-case textarea {
    height: 100px;
    width: 400px;
    margin-left: 10px;
    border-radius: 8px;
    padding: 0 10px;
    font-size: 16px;
    box-sizing: border-box;
    resize: none;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}


.img-case {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.img-case .img {
    width: 250px;
    height: 250px;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
}

.img-case .img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;

}

.img-btn {
    display: flex;
    white-space: nowrap;
    align-items: top;
    gap: 60px;
    margin-right: 110px;
}

.btn-case {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin-right: 40px;
}

.btn-case input[type="file"] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    width: 70px;
    height: 70px;
}


.upload-icon {
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' ?><svg height='70px' id='Layer_1' style='enable-background:new 0 0 512 512;' version='1.1' viewBox='0 0 512 512' width='70px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path d='M398.1,233.2c0-1.2,0.2-2.4,0.2-3.6c0-65-51.8-117.6-115.7-117.6c-46.1,0-85.7,27.4-104.3,67c-8.1-4.1-17.2-6.5-26.8-6.5 c-29.5,0-54.1,21.9-58.8,50.5C57.3,235.2,32,269.1,32,309c0,50.2,40.1,91,89.5,91H224v-80l-48.2,0l80.2-83.7l80.2,83.6l-48.2,0v80 h110.3c45.2,0,81.7-37.5,81.7-83.4C480,270.6,443.3,233.3,398.1,233.2z' fill='%23FFFFFF'/></svg>");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    cursor: pointer;
}

.delete-btn {
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' ?><svg fill='%23FFFFFF' height='70' viewBox='-2 -2 24 24' width='70' xmlns='http://www.w3.org/2000/svg'><path d='m11.414 10 2.829-2.828a1 1 0 1 0-1.415-1.415L10 8.586 7.172 5.757a1 1 0 0 0-1.415 1.415L8.586 10l-2.829 2.828a1 1 0 0 0 1.415 1.415L10 11.414l2.828 2.829a1 1 0 0 0 1.415-1.415L11.414 10zM4 0h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'/></svg>");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
}

.delete-btn div {
    display: none;
}


.send-case {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.send-case div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.send-case button {
    width: auto;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Orbitron', sans-serif;
    font-size: 18px;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
}

button.spl:hover {
    background-color: #7e1d87;
    color: white;
}

button.spl {
    background: #5A0080;
    color: white;
}

button.pump {
    background: #173ce0;
    color: white;
}

button.pump:hover {

    background: #3e60f8;
}

button.erc {
    background: #03b7bd;
    color: white;
}

button.erc:hover {
    background: #019499;
    color: white;
}

.auto-deploy {
    display: flex;
    white-space: nowrap;
    gap: 5px;
}

input[type="checkbox"] {
    height: 25px;
    width: 25px;
    border-radius: 25%;
    border: 2px solid #ccc;
    appearance: none;
    outline: none;
    cursor: pointer;
    background-color: #fff;

}

input[type="checkbox"]:checked {
    background-color: hsl(0, 0%, 100%);
    border: 2px solid #ffffff;
    position: relative;
}

input[type="checkbox"]:checked::before {
    content: '✓';
    color: rgb(0, 0, 0);
    font-weight: bold;
    position: absolute;
    left: 5px;
    top: 0;
    font-size: 18px;
}

.card p {
    color: white;
    margin-top: 50px;
    margin-left: 20px;
}

input,
textarea {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
}

.send-case button:disabled {
    background-color: grey;
    color: white;
    cursor: not-allowed;
    opacity: 0.6;
}

.tweetForm {
    margin-left: 10px;
}

.set-btn {
    width: 50px;
    height: 50px;
    background-image: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23ffffff%22%20width%3D%22800px%22%20height%3D%22800px%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M3%2C9A1%2C1%2C0%2C0%2C0%2C4%2C8V5A1%2C1%2C0%2C0%2C1%2C5%2C4H8A1%2C1%2C0%2C0%2C0%2C8%2C2H5A3%2C3%2C0%2C0%2C0%2C2%2C5V8A1%2C1%2C0%2C0%2C0%2C3%2C9ZM8%2C20H5a1%2C1%2C0%2C0%2C1-1-1V16a1%2C1%2C0%2C0%2C0-2%2C0v3a3%2C3%2C0%2C0%2C0%2C3%2C3H8a1%2C1%2C0%2C0%2C0%2C0-2ZM12%2C8a4%2C4%2C0%2C1%2C0%2C4%2C4A4%2C4%2C0%2C0%2C0%2C12%2C8Zm0%2C6a2%2C2%2C0%2C1%2C1%2C2-2A2%2C2%2C0%2C0%2C1%2C12%2C14ZM19%2C2H16a1%2C1%2C0%2C0%2C0%2C0%2C2h3a1%2C1%2C0%2C0%2C1%2C1%2C1V8a1%2C1%2C0%2C0%2C0%2C2%2C0V5A3%2C3%2C0%2C0%2C0%2C19%2C2Zm2%2C13a1%2C1%2C0%2C0%2C0-1%2C1v3a1%2C1%2C0%2C0%2C1-1%2C1H16a1%2C1%2C0%2C0%2C0%2C0%2C2h3a3%2C3%2C0%2C0%2C0%2C3-3V16A1%2C1%2C0%2C0%2C0%2C21%2C15Z%22/%3E%3C/svg%3E');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    cursor: pointer;


    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
}

.set-btn-case {
    margin-top: 55px;
}

.download-btn {
    width: 50px;
    height: 50px;
    background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%22548.176px%22%20height%3D%22548.176px%22%20viewBox%3D%220%200%20548.176%20548.176%22%20style%3D%22enable-background%3Anew%200%200%20548.176%20548.176%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%20transform%3D%22scale(-1%2C%201)%20translate(-548.176%2C%200)%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M524.326%2C297.352c-15.896-19.89-36.21-32.782-60.959-38.684c7.81-11.8%2C11.704-24.934%2C11.704-39.399%0A0-20.177-7.139-37.401-21.409-51.678c-14.273-14.272-31.498-21.411-51.675-21.411c-18.083%2C0-33.879%2C5.901-47.39%2C17.703%0A-11.225-27.41-29.171-49.393-53.817-65.95c-24.646-16.562-51.818-24.842-81.514-24.842c-40.349%2C0-74.802%2C14.279-103.353%2C42.83%0A-28.553%2C28.544-42.825%2C62.999-42.825%2C103.351c0%2C2.474%2C0.191%2C6.567%2C0.571%2C12.275c-22.459%2C10.469-40.349%2C26.171-53.676%2C47.106%0AC6.661%2C299.594%2C0%2C322.43%2C0%2C347.179c0%2C35.214%2C12.517%2C65.329%2C37.544%2C90.358c25.028%2C25.037%2C55.15%2C37.548%2C90.362%2C37.548h310.636%0Ac30.259%2C0%2C56.096-10.711%2C77.512-32.12c21.413-21.409%2C32.121-47.246%2C32.121-77.516C548.172%2C339.944%2C540.223%2C317.248%2C524.326%2C297.352%0Az%20M362.595%2C308.344L262.38%2C408.565c-1.711%2C1.707-3.901%2C2.566-6.567%2C2.566c-2.664%2C0-4.854-0.859-6.567-2.566L148.75%2C308.063%0Ac-1.713-1.711-2.568-3.901-2.568-6.567c0-2.474%2C0.9-4.616%2C2.708-6.423c1.812-1.808%2C3.949-2.711%2C6.423-2.711h63.954V191.865%0Ac0-2.474%2C0.905-4.616%2C2.712-6.427c1.809-1.805%2C3.949-2.708%2C6.423-2.708h54.823c2.478%2C0%2C4.609%2C0.9%2C6.427%2C2.708%0Ac1.804%2C1.811%2C2.707%2C3.953%2C2.707%2C6.427v100.497h63.954c2.665%2C0%2C4.855%2C0.855%2C6.563%2C2.566c1.714%2C1.711%2C2.562%2C3.901%2C2.562%2C6.567%0AC365.438%2C303.789%2C364.494%2C306.064%2C362.595%2C308.344z%22/%3E%3C/g%3E%3C/svg%3E');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    cursor: pointer;


    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
}

.download-btn-case {
    margin-top: 5px;
}

.delete-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -25px;
    left: 95%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.delete-btn::after {
    content: '';
    position: absolute;
    top: -25px;

    opacity: 0;
    visibility: hidden;
}

.upload-icon:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -25px;
    left: 95%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.upload-icon::after {
    content: '';
    position: absolute;
    top: -25px;

    opacity: 0;
    visibility: hidden;
}

.set-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 100px;
    left: 95%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.set-btn::after {
    content: '';
    position: absolute;
    top: 100px;

    opacity: 0;
    visibility: hidden;
}

.download-btn:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 170px;
    left: 95%;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.download-btn::after {
    content: '';
    position: absolute;
    top: 170px;

    opacity: 0;
    visibility: hidden;
}