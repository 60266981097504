nav {
    position: sticky;
    top: 0;
    height: 60px;
    width: 200px;
    font-size: 13px;
    text-align: center;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background-color);
}

nav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0;
    list-style: none;
    margin: 0;
    width: 100%;
    height: 100%;
}

nav ul li {
    position: relative;
    width: auto;
    height: 100%;
}

nav ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 10px;
    color: var(--white-color);
    text-decoration: none;
    outline: none;
    position: relative;
    height: 40px;
    margin-top: 10px;
}

.nav-monitor-feed-page .nav-icon {
    width: 50px;
    height: 50px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 32 32%22 width=%2240px%22 height=%2240px%22 fill=%22none%22 stroke=%22white%22 stroke-linejoin=%22round%22 stroke-miterlimit=%2210%22 stroke-width=%222%22%3E%3Cg%3E%3Cpath d=%22M25,3V1H3v28 c0,1.105,0.895,2,2,2h22c1.105,0,2-0.895,2-2V3H25z%22 /%3E%3Crect height=%226%22 width=%2214%22 x=%227%22 y=%225%22 /%3E%3Cline x1=%2225%22 x2=%2225%22 y1=%223%22 y2=%2224%22 /%3E%3Cline x1=%2225%22 x2=%2225%22 y1=%2226%22 y2=%2228%22 /%3E%3Cline x1=%226%22 x2=%2222%22 y1=%2215%22 y2=%2215%22 /%3E%3Cline x1=%226%22 x2=%2222%22 y1=%2219%22 y2=%2219%22 /%3E%3Cline x1=%226%22 x2=%2218%22 y1=%2223%22 y2=%2223%22 /%3E%3C/g%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.nav-monitor-feed-page .nav-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 3px 6px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-monitor-feed-page:hover .nav-item {
    opacity: 1;
    visibility: visible;
}

.nav-token-create .nav-icon {
    width: 50px;
    height: 50px;
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" width="30" height="30"> <title/> <g> <path d="M66,42H54V30a6,6,0,0,0-12,0V42H30a6,6,0,0,0,0,12H42V66a6,6,0,0,0,12,0V54H66a6,6,0,0,0,0-12Z" fill="white"/> <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" fill="white"/> </g> </svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.nav-token-create .nav-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 3px 6px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-token-create:hover .nav-item {
    opacity: 1;
    visibility: visible;
}