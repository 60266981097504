.body {
    display: flex;
    white-space: nowrap;
    gap: 20px;
    margin-top: 10px;
}

.load {
    color: white;
    margin-top: 50px;
    margin-left: 20px;
}